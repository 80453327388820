import React from 'react';
import './Home.scss';
import HomePageParticles from "../../Components/Effects/HomePageParticles";
import NavBar from "../../Components/Common/NavBar/NavBar";
import Slider from "./Slider";
import Aboutme from "./Aboutme";
import Projects from "./Projects";
import Footer from "../../Components/Common/Footer/Footer";
import Contact from "./Contact";

class Home extends React.Component {
    render() {
        return (
            <div id={"home"}>
                <NavBar/>
                <div id="slider">
                    {HomePageParticles()}
                    <Slider/>
                </div>
                <Aboutme/>
                <Projects/>
                <Contact/>
                <Footer/>
            </div>
        );
    }
}

export default Home;