import React from 'react';
import './Projects.scss';
import YoutubeChannel from "../../Assets/Images/youtube_channel.png";
import Template from "../../Assets/Images/template.jpg";
import AerialPhoto from "../../Assets/Images/aerialphoto.jpg";
import Dashboard from "../../Assets/Images/dashboard.jpg";
class Projects extends React.Component {
    render() {
        return (
            <div id={"projects"}>
                <div className={"projects-container"}>
                    <span>Projects</span>
                    <h2>What projects did I work on?</h2>
                    <div className={"project"}>
                        <div className={"project-title"}>Music Promotion</div>
                        <p>I've successfully managed multiple Youtube channels over the years with over 20 million total views and 100,000+ subscribers. All Youtube channels I owned/managed where related to music promotion.</p>
                        <img src={YoutubeChannel} alt="Project thumbnail"/>
                    </div>
                    <div className={"project"}>
                        <div className={"project-title"}>ISO 27001 ISMS Template</div>
                        <p>During my traineeship at a cybersecurity company I created a template for ISO 27001 certifying. This helps companies get ISO certified. I created this template using Jira and Confluence.</p>
                        <img src={Template} alt="Project thumbnail"/>
                    </div>
                    <div className={"project"}>
                        <div className={"project-title"}>AI Project</div>
                        <p>While working for an ai company i've created an application that is able to detect mutations in aerial photos over a different time period. This way municipalities are able to track newly constructed buildings.</p>
                        <img src={AerialPhoto} alt="Project thumbnail"/>
                    </div>
                    <div className={"project"}>
                        <div className={"project-title"}>Vulernabilty scanning dashboard</div>
                        <p>Last year my task was to create a dashboard for a vulnerabilty scanner. I managed databases, used API's, worked out a design and did the construction of the backend. To make everything look clear I created dynamic charts.</p>
                        <img src={Dashboard} alt="Project thumbnail"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Projects;