import React from 'react';
import './Footer.scss';

const Footer = () => {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <footer id={"Footer"}>
            Copyright &#169; {year} Julius Ruhe. All rights reserved.
        </footer>
    )
}

export default Footer;