import React, { Component } from 'react';
import './NavBar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMusic, faWifi, faBars, faPhone } from '@fortawesome/free-solid-svg-icons';

class NavBar extends Component {
    constructor() {
        super();
        this.state = {
            isMenuActive: false,
        };
    }

    toggleMenu = () => {
        this.setState({ isMenuActive: !this.state.isMenuActive });
    };

    render() {
        const { isMenuActive } = this.state;

        return (
            <div id="navbar">
                <a className={"brand-title"} href="">
                    <FontAwesomeIcon icon={faWifi} className="navbar-logo"/> Julius Ruhe <FontAwesomeIcon icon={faMusic} className="navbar-logo"/>
                </a>
                <a href="#" className={`toggle-button ${isMenuActive ? 'active' : ''}`} onClick={this.toggleMenu}>
                    <FontAwesomeIcon icon={faBars} className="navbar-hamburger"/>
                </a>
                <div className={`navbar-links ${isMenuActive ? 'active' : ''}`}>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#aboutme">About Me</a></li>
                        <li><a href="#projects">Projects</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default NavBar;
