import React from 'react';
import "./Slider.scss";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import slide1 from "../../Assets/Images/slide1.jpg";
import slide2 from "../../Assets/Images/slide2.jpg";
import slide3 from "../../Assets/Images/slide3.jpg";
const Example = () => {
    return (
        <Slide autoplay={true}>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${slide1})` }}></div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${slide2})` }}></div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${slide3})` }}></div>
            </div>
        </Slide>
    );
};

export default Example;