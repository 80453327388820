import './App.scss';
import Home from "./Pages/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className={"App"}>
        <div className={"Portfolio-content"}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </BrowserRouter>
        </div>
    </div>
  );
}

export default App;
