import React from 'react';
import './Contact.scss';
import ContactImage from "../../Assets/Images/contact.jpg";

class Contact extends React.Component {
    render() {
        return (
            <div id={"contact"}>
                <div className={"contact-container"}>
                    <span>Contact</span>
                    <h2>Contacting me is easy!</h2>
                    <p>Feel free to reach out! Whether you have an exciting project in mind, want to discuss collaboration opportunities, or just want to connect over shared interests, I'd love to hear from you.</p>
                    <a href="mailto:juliusruhe@gmail.com">Send email</a>
                </div>
                <img className={"contact-image"} src={ContactImage} alt="Contact image"/>
            </div>
        );
    }
}

export default Contact;