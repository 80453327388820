import React from 'react';
import './Aboutme.scss';
import aboutme from "../../Assets/Images/about_me.png";

class Aboutme extends React.Component {
    render() {
        return (
            <div id={"aboutme"}>
                <div className={"aboutme-container"}>
                    <span>About Me</span>
                    <h2>So, who am I?</h2>
                    <p>Hey, I'm Julius, an aspiring IT enthusiast with a passion for technology and a love for the music industry. I thrive on crafting creative solutions for projects in the IT and music industry. From coding challenges to discovering the latest beats, my journey is all about harmonizing creativity and technology. Explore my portfolio, and feel free to contact me. Let's make waves in both IT and music!</p>
                    <a href="#contact">Contact me</a>
                </div>
                <img className={"aboutme-image"} src={aboutme} alt="Image aboutme"/>
            </div>
        );
    }
}

export default Aboutme;